import React from "react"
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import { Inner } from "../../components/Interface"
const Post = ({
  pageContext: { id, postId, title, content, excerpt, next, prev },
}) => {
  return (
    <Layout>
      <SEO title="Home" description={title} />
      <div>
        <Container>
          <Row>
            <Col>
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
            </Col>
          </Row>
          <Row>
            <Col
              sm="12"
              md={{ size: 8, offset: 2 }}
              className="font-weight-light "
              style={{ fontSize: 20 }}
            >
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default Post
